import React from 'react';

import { withAuthorization } from '../Session';
import Tree from 'react-tree-graph';
import 'react-tree-graph/dist/style.css'
import './home.css';
import Math from '../Math';


let data = {
	name: 'LeetKid',
	children: [{
    name: 'Math', 
    children: [
      { name: 'Addition',
        children: [
          { name: 'Single Digit Addition',
            gProps: {
            className: 'problemSet',
            onClick: (event, node) =>
              alert(`Clicked ${node}!`)
          }},
          { name: 'Double Digit Addition'},
        ]},
      { name: 'Subtraction', 
        children: [
          { name: 'Single Digit Subtraction'},
          { name: 'Double Digit Subtraction'},
        ]},
      { name: 'Multiplication'},
      { name: 'Division'} 
    ]
	}, {
    name: 'English',
    children: [
      { name: 'Spelling'}
    ]
  }]
};

const HomePage = () => (
  <div className="custom-container">
    <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed in user.</p>
    <Tree
      data={data}
      height={200}
      width={400}
      animated={true}
      svgProps={{
        className: 'custom',
      }}
      />
      <Math />
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);