import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import { SignInForm } from '../SignIn';
import { SignUpLink } from '../SignUp';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import {withAuthentication} from '../Session';
//import { sign } from 'crypto';


const UserDropdownMenu = () => (
    <div>
        <a className="dropdown-item" href={ROUTES.ACCOUNT}>Account</a>
        <a className="dropdown-item" href={ROUTES.LANDING}><SignOutButton /></a> 
    </div>
);

class Navigation extends Component {

    constructor(props){
        super(props);
        this.state = { mathActive : "",
                        spellingActice: ""
        };

        //this.selectItem = this.selectItem.bind(this);
        
    }
    
    render() {
        
        return (
            <AuthUserContext.Consumer>
                
                {authUser => (
        
                <nav className="navbar navbar-expand-md navbar-light bg-light justify-content-between">
                    <a className="navbar-brand" href={ROUTES.HOME}>K</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href={ROUTES.MATH}>Math</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ROUTES.SPELLING}>Spelling</a>
                            </li>
                        </ul>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <span className="nav-item dropdown navbar-nav ml-auto">
                        <a className="nav-link dropdown-toggle" href={ROUTES.ACCOUNT} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {authUser? authUser.email : "Sign in"}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            { authUser? <UserDropdownMenu /> : <div><SignInForm /> <SignUpLink /></div>}
                        </div>
                        </span>
                    </div>
                    </nav> )}

          </AuthUserContext.Consumer>
        );
    }
}

export default withAuthentication(Navigation);
