import React from 'react';
import { Link } from 'react-router-dom';
import YAStarRating from '../StarRating';



const ProblemSets = [
  { code: "M1", desc: "Single digit addition" },
  { code: "M2", desc: "Double digit addition" },
  //{ code: "M3", desc: "Single digit subtraction"},
  //{ code: "M4", desc: "Double digit subtraction"},
  //{ code: "M5", desc: "Single digit negative" },
  //{ code: "M6", desc: "Double digit negative" },
  //{ code: "M7", desc: "Triple digit positive" },
  //{ code: "M8", desc: "Triple digit negative" }
]

const Math = () => (
  <div>
    <h3>Math Problem Sets:</h3>
    <ol>
      {ProblemSets.map(p => 
      
      <li key={p.code}><Link to={"/math/" + p.code} replace>{p.desc}</Link><YAStarRating scale={0.3} pset={p.code}/></li>
      
      
      )}
    </ol>
  </div>
);

export default Math;
