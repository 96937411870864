import React, { Component } from 'react';
import { withAuthentication } from '../Session';

class YAStarRating extends Component {
    
    constructor(props){
        super(props);

        this.state = {percent:0};
    }

    static defaultProps = {
        starCount: 5,
        scale: 1,
        pset: 'M'
    }

    componentDidMount() {
        this.props.firebase.getLastFiveScore({uid:sessionStorage.getItem('authUserID'), pset:this.props.pset, 
            callBack: 
                (fbscores) => { 
                    fbscores
                    ? this.setState({ percent: 2 * Object.values(fbscores).map(o => o.score).reduce((prev, cur) => prev + cur) })
                    : this.setState({ percent: 0 });
                    //console.log("YAstar: " + Object.values(fbscores).map(o => o.score));
                }
        });
    }

    componentWillUnmount() {
        this.props.firebase.turnOffLastFiveScores({uid:sessionStorage.getItem('authUserID'), pset:this.props.pset});
    }

    render() {
        let stars = [];
        let remainingpct = this.state.percent;
        let starpct = 100 / this.props.starCount;
        let createdStars = 0;
        while ( remainingpct > starpct) {
            stars.push(<YAStar scale={this.props.scale} percentFilled={100} key={createdStars} />);
            remainingpct -= starpct;
            createdStars++;
        }
        if ( remainingpct > 0 ) {
            stars.push(<YAStar scale={this.props.scale} percentFilled={100 * remainingpct / starpct} key={createdStars}/>);
            createdStars++;
        }
        while (createdStars < this.props.starCount){
            stars.push(<YAStar scale={this.props.scale} percentFilled={0} key={createdStars}/>)
            createdStars++;
        }

        return (
            <div key="this.props.percent">
            {stars}
            </div>
        );
    }
}

const YAStar = ({scale=1, percentFilled=100, fillColor="gold", emptyColor="grey", borderColor="#FF4500", borderThickness=2}) => (
    <svg height={60 * scale} width={60 * scale}>
        <defs>
            <linearGradient id="grad" x1={2 * percentFilled - 100 + "%"} x2="100%" y1="0" y2="0">
                <stop offset="50%" stopColor={fillColor}/>
                <stop offset="50%" stopColor={emptyColor}/>
            </linearGradient>
        </defs>
        <path  d={
            ['M',30.000,40.000,'L',47.634,54.271,'L',39.511,33.090,'L', 58.532, 20.729,'L', 35.878, 21.910,'L', 30.000, 0.000,'L', 24.122, 21.910,'L', 1.468, 20.729,'L', 20.489, 33.090,'L',12.366, 54.271,'L', 30.000, 40.000]
            .map(c => typeof(c) === "number" ? c * scale + 1 : c).join(" ")
         } strokeWidth={borderThickness * scale} fill="url(#grad)" stroke={borderColor} strokeLinecap="round"/>
    </svg>
);

export default withAuthentication(YAStarRating);