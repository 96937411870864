import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  };

class Firebase {
  constructor() {

    app.initializeApp(config);
    
    this.auth = app.auth();
    this.db = app.database();
  }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** User API ***

    user = uid => this.db.ref(`users/${uid}`);
    userpsetscores = ({uid, pset}) => this.db.ref(`users/${uid}/${pset}/scores`);
    

    users = () => this.db.ref('users');

    yo = () => console.log("Yo");

    currentID = () => console.log(this.auth.currentUser);

    addScore = ({uid, pset, score}) => {
      //this.userpsetscores({uid:uid, pset:pset}).push().set({score});
      let d = new Date();
      let time = d.getTime();
      this.userpsetscores({uid:uid, pset:pset}).push({score, time});
    };

    getScore = ({uid, pset, callBack}) => { 
      console.log("getting score " + uid + " " + pset);
      this.userpsetscores({uid:uid, pset:pset}).once('value', snapshot => {
        const scores = snapshot.val();
        callBack(scores);
         //return (snapshot.val() || 0 )});
        }, errorObject => {
          console.log("The read failed: " + errorObject.code);
        });
    };

    getLastFiveScore = ({uid, pset, callBack}) => { 
      //console.log("getting score " + uid + " " + pset);
      this.userpsetscores({uid:uid, pset:pset}).limitToLast(5).on('value', snapshot => {
        if (snapshot.val()){
          const scores = snapshot.val();
          callBack(scores);
        } else {
          return (null)
        }
         //return (snapshot.val() || 0 )});
      }, errorObject => {
          console.log("The read failed: " + errorObject.code);
      });
    };

    turnOffLastFiveScores = ({uid, pset}) => {
      this.userpsetscores({uid:uid, pset:pset}).off();
    }
}

export default Firebase;