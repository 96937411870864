import React, { Component } from 'react';
import './M1.css';
import YAStarRating from '../StarRating';
//import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';
//import { AuthUserContext } from '../Session';


const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));


class M1Page extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            problems: [],
            answers: [],
            submitted: false,
            pset: props.match.params.id,
            scores: [0,0,0,0,0]
        };
        
        this.initState = this.initState.bind(this);
        this.answerChanged = this.answerChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        //generate problem set and put into local storage to preserve through browser refresh
        if (localStorage.getItem(this.state.pset + "problems") == null) { 
            
            this.initState();

        } 
        else {
            this.setState({ 
                    problems: JSON.parse(localStorage.getItem(this.state.pset + "problems")),
                    answers: JSON.parse(localStorage.getItem(this.state.pset + "answers")),
                    submitted: localStorage.getItem(this.state.pset + "submitted"),
                    pset: this.state.pset
            });
        }
   }

    initState(){
        
        const problems = [];
        //set up problem sets...
        switch (this.state.pset){
            case 'M2': //double digit addition
                for (let i = 0; i < 10; i++){
                    problems.push([getRandomInt(50), getRandomInt(50)]);
                }
                break;
            default: //single digit addition
                for (let i = 0; i < 10; i++){
                    problems.push([getRandomInt(10), getRandomInt(10)]);
                }
        }
        
        localStorage.setItem(this.state.pset + "problems", JSON.stringify(problems));
        localStorage.setItem(this.state.pset + "answers", JSON.stringify(["","","","","","","","","",""]));
        localStorage.setItem(this.state.pset + "submitted", false);


        //Setting state directly from problems without going through local state doesn't seem to work ...
        this.setState({ 
            problems: JSON.parse(localStorage.getItem(this.state.pset + "problems")),
            answers: JSON.parse(localStorage.getItem(this.state.pset + "answers")),
            submitted: false,
            pset: this.state.pset
        });
    }

    handleSubmit(event){
        
        if (this.state.submitted) { //clicked 'reload'
           this.initState();
        } else {  //clicked 'submit'
            

            let score = 0;
            for (let i = 0; i < 10; i++){
                if (this.state.problems[i][0] + this.state.problems[i][1] === Number(this.state.answers[i])){
                    score++;
                }
            }
    
            event.preventDefault();
            this.setState({
                submitted: true
            });
            localStorage.setItem(this.state.pset + "submitted", true);
            
            let id = sessionStorage.getItem('authUserID');
            if (id) {
                this.props.firebase.addScore({uid:id, pset:this.state.pset, score:score});
            }
        }
    }

    answerChanged(event){
        event.preventDefault();
        if (this.state.submitted) { return }
        let newAnswers = [...this.state.answers];
        newAnswers[event.target.name] = event.target.value;
        localStorage.setItem(this.state.pset + "answers", JSON.stringify(newAnswers));
        this.setState({
            answers: newAnswers,
        });
    }

    render(){
        let i = 0;
        let operator = "+";
        switch (this.state.pset){
            default:
                break;
        }

        return (
            
                <div className="content">
                    {this.state.problems.map(n => 
                        <div className="problemWrapper" key={i}>
                            <M1Problem  prob={n} 
                                ans={this.state.answers[i]} 
                                submitted={this.state.submitted} 
                                correct={n[0] + n[1] === Number(this.state.answers[i])} 
                                i={i++}
                                callBack={(e) => this.answerChanged(e)} 
                                operator={operator}
                                />
                                <div className={this.state.submitted? "correctCheck on" : "correctCheck"}>
                                    <img src="../icons8-checkmark-200.png" alt="Correct!" height="200" width="200"></img>
                                </div>
                        </div>
                    )}

                    <button type="button" id="submit_button" onClick={this.handleSubmit}>{this.state.submitted? "Reload!" : "Submit!"}</button>
                    <div id="stars" align="center">
	                    
                        <YAStarRating scale={0.6} pset={this.state.pset}/>

                    </div>
                </div>
        
        );
    }
}

const M1Problem = (props) => (
    <div className={!props.submitted? "problem": props.correct ? "problem correct" : "problem wrong"}>
        <div style={{textalign:'right'}}>
            {
            props.prob[0].toString().length === 1? '\u00A0\u00A0\u00A0' + props.prob[0] :
            props.prob[0].toString().length === 2? '\u00A0\u00A0' + props.prob[0]: '\u00A0' + props.prob[0]
            }
        </div>
        <div style={{textalign:'right'}}>
            <u>{props.operator}
            {
            props.prob[1].toString().length < 2? '\u00A0\u00A0' + props.prob[1] :
            props.prob[1].toString().length === 2? '\u00A0' + props.prob[1]: props.prob[1]
            }
            </u>
        </div>
        <div>
            <input className={!props.submitted? "answer": props.correct ? "answer correct" : "answer wrong"} 
                name={props.i} 
                value={props.ans} 
                onChange={props.callBack} autoComplete="off"/>
        </div>
    </div>
)

export default withAuthentication(M1Page);